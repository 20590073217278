html {
  font-size: 63% !important;
  box-sizing: border-box;
}

input {
  outline: none;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

button {
  border: 0;
  cursor: pointer;
  background: none;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

h2 {
  font-size: 2.6rem;
  font-weight: 500;
  margin: 0;
}

h3 {
  margin: 0;
}

textarea {
  flex: 1;
  background-color: #f7f7f7;
  padding: 7px 10px;
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  font-family: 'Gravur-CondensedLight';
  border: 1px solid #bfbfbf;
  color: #595959;
}

/**
 *
 */
/**
 * Oranges and Grays - main colors (dashboard)
 */
/**
 * Extended Editor colors
 */
/* --------------------------------------------------------
Fonts of Dot
author: pedro, emanuel
email: pedro@dot.vu, emanuel@dot.vu
website: http://dot.vu
----------------------------------------------------------*/
/**
 * REQUIRES
 ************************/
/**
 * Requires Modernizer test (on init.js)
 */
/* golden height */
/* CLEAR FLOATS*/
/* MOBILE */
/* DESKTOP */
/* ASPECT RATIO */
/* INPUT STYLING */
/* GRID LIKE BEHAVIOR */
/* BACKGROUNDS */
/*******************************************************************
 *
 * Tables
 *
 *******************************************************************/
/*******************************************************************
 *
 * Fluid Iframe
 *
 *******************************************************************/
/*******************************************************************
 *
 * Fluid Cover Image
 *
 *******************************************************************/
/*******************************************************************
 *
 * Box Shadows
 *
 *******************************************************************/
/*******************************************************************
 *
 * Background Image Mixin
 *
 *******************************************************************/
/*******************************************************************
 *
 * Flex
 *
 *******************************************************************/
/*******************************************************************
 *
 * Buttons
 *
 *******************************************************************/

@font-face {
  font-family: 'Gravur-CondensedBold';
  src: url(/public/fonts/gravure/GraCoBd_.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Gravur-CondensedLight';
  src: url(/public/fonts/gravure/GraCoLt_.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

.feenstra-autocomplete-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
  padding: 10px 20px;
  background-color: white;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f7f7f7;
  color: #333333;
  font-size: small;
  font-weight: bold;
}

.feenstra-autocomplete-dropdown input {
  width: 100%;
  padding: 0;
  border: 0;
  margin: 5px 0;
  color: #333333;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Gravur-CondensedBold';
}

.feenstra-autocomplete-dropdown .icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  border-radius: 50%;
}

.feenstra-autocomplete-dropdown .icon.close {
  width: 20px;
  height: 20px;
}

.feenstra-autocomplete-dropdown .icon.arrow {
  width: 30px;
  height: 30px;
}

.feenstra-autocomplete-dropdown .icon:hover {
  background: #dddddd;
}

.feenstra-autocomplete-dropdown-options-menu {
  position: absolute;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f7f7f7;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.feenstra-autocomplete-dropdown-options-menu button {
  text-align: left;
  padding: 5px;
}

.feenstra-autocomplete-dropdown-options-menu button span {
  color: #333333;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Gravur-CondensedBold';
}

.feenstra-autocomplete-dropdown-options-menu button.focused {
  background-color: #dddddd;
}

.feenstra-autocomplete-dropdown-options-menu button:hover,
.feenstra-autocomplete-dropdown-options-menu button.selected {
  background-color: #f79441;
}

.feenstra-autocomplete-dropdown-options-menu button:hover span,
.feenstra-autocomplete-dropdown-options-menu button.selected span {
  color: #ffffff;
}

.feenstra-call-center {
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feenstra-call-center .logo {
  height: 38px;
}

.feenstra-call-center .extra-summary-questions {
  margin-top: 20px;
}

.feenstra-call-center .feenstra-nos-checkbox {
  margin-top: 8px;
  margin-bottom: 8px;
  /* Hide the default checkbox input */
  /* Custom checkbox styles */
  /* Custom checkbox styles when checked */
  /* Custom checkbox label text styles */
}

.feenstra-call-center .feenstra-nos-checkbox label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  font-weight: lighter;
  font-family: 'Gravur-CondensedLight';
}

.feenstra-call-center .feenstra-nos-checkbox input {
  display: none !important;
}

.feenstra-call-center .feenstra-nos-checkbox label::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  border: 1px solid #333;
  border-radius: 50%;
  background-color: #fff;
  font-size: 11px;
  text-align: center;
  color: white;
  -webkit-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.feenstra-call-center .feenstra-nos-checkbox input:checked + label::before {
  background-color: #f79441;
  border-color: #f79441;
  content: '✓';
}

.feenstra-call-center .feenstra-nos-checkbox label span {
  line-height: 1.5;
}

.feenstra-call-center .primary-button {
  margin: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f79441;
  border: 2px solid #f79441;
  color: white;
  border-radius: 25px;
  padding: 13px 40px;
  font-size: 2rem;
  font-family: 'Gravur-CondensedBold';
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.feenstra-call-center .primary-button:hover,
.feenstra-call-center .primary-button.selected {
  background-color: #333333;
  border-color: #333333;
  color: #ffffff;
}

.feenstra-call-center .primary-button:active {
  background-color: #333333;
  border-color: #333333;
  color: #f79441;
}

.feenstra-call-center .secondary-button {
  margin: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #f79441;
  color: #f79441;
  border-radius: 25px;
  padding: 13px 40px;
  font-size: 2rem;
  font-family: 'Gravur-CondensedBold';
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.feenstra-call-center .secondary-button:hover,
.feenstra-call-center .secondary-button.selected {
  background-color: #f79441;
  color: #333333;
}

.feenstra-call-center .secondary-button:active {
  background-color: #f79441;
  color: #333333;
}

.feenstra-call-center .tertiary-button {
  margin: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  color: #f79441;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.feenstra-call-center .icon {
  position: relative;
  display: flex;
}

.feenstra-call-center .tertiary-button span.icon *,
.feenstra-call-center .tertiary-button svg * {
  fill: #f79441;
}

.feenstra-call-center .tertiary-button,
.feenstra-call-center .tertiary-button span.text {
  color: #f79441;
  font-size: 1.8rem;
  line-height: 1;
  font-family: 'Gravur-CondensedLight';
}

.feenstra-call-center .tertiary-button > .icon {
  width: 12px;
  height: 12px;
}

.feenstra-call-center .tertiary-button > .icon {
  margin-right: 12px;
  margin-top: 1px;
}

.feenstra-call-center .tertiary-button:hover,
.feenstra-call-center .tertiary-button:active {
  text-decoration: underline;
}

.feenstra-call-center .tertiary-button.gallery-button > .icon.gallery svg {
  width: 18px;
  height: 18px;
}

.feenstra-call-center .tertiary-button.gallery-button .notification {
  position: relative;
  background-color: red;
  border-radius: 50%;
  line-height: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px;
  margin-left: 7px;
}

.feenstra-call-center .tertiary-button.gallery-button .notification span {
  color: white;
  font-family: 'Gravur-CondensedBold';
  font-size: 1.4rem;
}

.feenstra-call-center .tertiary-button.gallery-button .notification::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.feenstra-call-center .screen-error-message {
  font-family: 'Gravur-CondensedBold';
  font-size: 1.8rem !important;
  color: red !important;
  margin-top: 0;
  margin-bottom: 0;
}

.feenstra-call-center .screen-title {
  font-family: 'Gravur-CondensedBold';
  font-size: 3.5rem;
  color: #333333;
  margin-top: 0;
  margin-bottom: 0;
}

.feenstra-call-center .screen-subtitle {
  font-family: 'Gravur-CondensedBold';
  font-size: 2rem;
  font-weight: bold;
  color: #333333;
  margin-top: 0;
  margin-bottom: 0;
}

.feenstra-call-center .screen-description {
  font-family: 'Gravur-CondensedLight';
  font-size: 1.7rem !important;
  white-space: pre-wrap;
  color: #333333 !important;
  margin-top: 10px;
  margin-bottom: 0;
}

.feenstra-call-center select.dropdown {
  width: 100%;
  margin: 20px 0px;
  padding: 10px 20px;
  background-color: white;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f7f7f7;
  color: #333333;
  font-size: small;
  font-weight: bold;
}

.feenstra-call-center input.input {
  width: 100%;
  margin: 20px 0px;
  padding: 10px 20px;
  background-color: white;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f7f7f7;
  color: #333333;
  font-size: small;
  font-weight: bold;
}

.feenstra-call-center .header {
  margin: 15px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feenstra-call-center .header .header-left {
  flex-grow: 1;
}

.feenstra-call-center .header .header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.feenstra-call-center .header .header-right h2 {
  font-family: 'Gravur-CondensedBold' !important;
}

.feenstra-call-center .body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f79441;
  position: relative;
}

.feenstra-call-center .background-image {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.feenstra-call-center .init {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.feenstra-call-center .init .central-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 100px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.feenstra-call-center .init .central-box .controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.feenstra-call-center .session {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feenstra-call-center .session .session-body,
.feenstra-call-center .session .session-footer-inner {
  max-width: 1400px;
  width: 100%;
  padding: 0 20px;
}

.feenstra-call-center .session .session-header {
  background-color: #f79441;
  height: 25px;
  width: 100%;
}

.feenstra-call-center .session .session-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 60px 20px;
}

@media screen and (max-height: 800px) {
  .feenstra-call-center .session .session-body {
    margin: 20px 20px;
  }
}

.feenstra-call-center .session .session-body > .screen,
.feenstra-call-center .session .session-body > .loading,
.feenstra-call-center .session .session-body > .error {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-right: 40px;
}

.feenstra-call-center .session .session-body > .screen > div,
.feenstra-call-center .session .session-body > .loading > div,
.feenstra-call-center .session .session-body > .error > div {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feenstra-call-center .session .session-body .session-controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: top;
  -webkit-align-items: top;
  -ms-flex-align: top;
  align-items: top;
  -webkit-box-pack: left;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  justify-content: left;
  height: 100%;
  min-width: 400px;
  row-gap: 20px;
}

.feenstra-call-center .session .session-body .final-screen-summary {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.feenstra-call-center .session .session-body .editable-summary-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feenstra-call-center
  .session
  .session-body
  .editable-summary-container
  .buttons-group {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.feenstra-call-center .session .session-body .editable-summary {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 960px;
  margin-top: 30px;
  row-gap: 10px;
}

.feenstra-call-center .session .session-body .editable-summary textarea {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f7f7f7;
}

.feenstra-call-center .session .session-body .summary {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #ffffff;
  border: 2px solid #dddddd;
  border-radius: 6px;
  padding: 20px;
  min-width: 400px;
  max-width: 400px;
  min-height: 400px;
}

.feenstra-call-center .session .session-body .summary h3.title {
  font-size: 2rem;
  font-family: 'Gravur-CondensedBold';
  color: #f79441;
}

.feenstra-call-center .session .session-body .summary p.summary-entry {
  font-size: 1.4rem;
  color: #333333;
  margin-top: 10px;
}

.feenstra-call-center .session .session-body .summary p.summary-entry .title {
  font-weight: bold;
  font-family: 'Gravur-CondesedBold';
}

.feenstra-call-center .session .session-body .summary p.summary-entry .value {
  font-weight: lighter;
  font-family: 'Gravur-CondensedLight';
}

.feenstra-call-center .session .session-footer {
  width: 100%;
  height: 100px;
  background-color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.feenstra-call-center .session .session-footer .session-footer-inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.feenstra-call-center .session .session-footer .session-footer-inner > .left {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feenstra-call-center .session .session-footer .session-footer-inner > .right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-column-gap: 80px;
  -moz-column-gap: 80px;
  column-gap: 80px;
}

.feenstra-call-center .buttons-group {
  margin-bottom: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  row-gap: 20px;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  margin-top: 30px;
}

@media screen and (max-height: 800px) {
  .feenstra-call-center .buttons-group {
    margin-bottom: 20px;
  }
}

.feenstra-call-center .image-gallery {
  border-radius: 3px;
}

.feenstra-call-center .image-gallery .image-gallery-item {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.feenstra-call-center .image-gallery .image-gallery-item img {
  cursor: pointer;
  height: 150px;
}

.feenstra-call-center
  .image-gallery
  .image-gallery-item
  .image-gallery-item-zoom-in-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.feenstra-call-center
  .image-gallery
  .image-gallery-item
  .image-gallery-item-zoom-in-icon
  svg {
  width: 30px;
  height: 30px;
}

.feenstra-call-center
  .image-gallery
  .image-gallery-item:hover
  .image-gallery-item-zoom-in-icon {
  opacity: 1;
}

.feenstra-call-center .fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1000;
}

.feenstra-call-center .fullscreen-image .image-container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1000;
}

.feenstra-call-center .fullscreen-image .image-container img {
  width: 1000px;
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.feenstra-call-center .image-controls {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.feenstra-call-center .image-controls .previous-icon,
.feenstra-call-center .image-controls .next-icon {
  position: absolute;
  cursor: pointer;
  top: 50vh;
}

.feenstra-call-center .image-controls .previous-icon {
  left: 2vw;
}

.feenstra-call-center .image-controls .next-icon {
  transform: rotate(180deg);
  right: 2vw;
}

.feenstra-call-center .fullscreen-image .zoom-out-icon {
  position: absolute;
  top: 5vh;
  right: 5vw;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.feenstra-call-center .fullscreen-image .zoom-out-icon,
.feenstra-call-center .image-controls img {
  width: 45px;
  height: 45px;
}

.feenstra-call-center .image-pagination {
  position: absolute;
  bottom: 4vh;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-radius: 9999px;
  display: flex;
  gap: 7.5px;
}

.feenstra-call-center .image-pagination .pagination-bullet {
  height: 15px;
  border-radius: 9999px;
  cursor: pointer;
}

.feenstra-call-center .image-pagination .pagination-bullet.active-bullet {
  width: 22.5px;
  background-color: orange;
}
.feenstra-call-center .image-pagination .pagination-bullet.inactive-bullet {
  width: 15px;
  background-color: gray;
}

.feenstra-call-center .feedback-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1000;
}

.feenstra-call-center .feedback-popup-container .feedback-popup {
  background-color: #ffffff;
  width: 50vw;
  height: 40vh;
  border-radius: 3px;
  padding: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 20px;
}

.feenstra-call-center
  .feedback-popup-container
  .feedback-popup
  .feedback-popup-error {
  font-size: 1.5rem;
  color: #333333;
}

.feenstra-call-center
  .feedback-popup-container
  .feedback-popup
  .feedback-popup-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feenstra-call-center
  .feedback-popup-container
  .feedback-popup
  .feedback-popup-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feenstra-call-center
  .feedback-popup-container
  .feedback-popup
  .feedback-popup-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.feenstra-call-center .feedback-popup-container .feedback-popup textarea {
  font-family: 'Gravur-CondensedLight';
  font-size: 1.4rem;
  border: 2px solid #dddddd;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  height: 100%;
  resize: none;
  color: #333333;
}

.feenstra-call-center .fullscreen-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.feenstra-call-center .fullscreen-modal-container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 40px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-family: 'Gravur-CondensedLight';
  overflow: auto;
}
